import Resizer from "react-image-file-resizer";

export const resizeFile = (file, isOneImage = true) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            1920, // Giới hạn chiều rộng tối đa là 1920px
            undefined, // Không giới hạn chiều cao
                "JPEG", // Định dạng ảnh
            100, // Chất lượng ảnh tối đa
            0, // Xoay ảnh nếu cần
            async (uri) => {
                let blob = uri;
                let maxSize = isOneImage ? 1024 * 1024 : 1024 * 1024 / 3
                let quality = 1; // Bắt đầu giảm chất lượng từ 90%
                // Kiểm tra nếu kích thước ảnh lớn hơn 1MB, tiếp tục giảm chất lượng
                while (blob.size > maxSize && quality > 0.1) { // 1MB = 1024 * 1024 bytes
                    quality -= 0.005 // Giảm chất lượng mỗi lần nén thêm
                    blob = await reduceImageSize(blob, quality);      
                }
                resolve(blob);
            },
            "blob" // Trả về đối tượng blob
        );
    });

const reduceImageSize = (blob, quality) =>
    new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = image.width;
                canvas.height = image.height;
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((newBlob) => resolve(newBlob), "image/jpeg", quality);
            };
        };
    });
