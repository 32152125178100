import React, { useState, useEffect, useRef } from 'react';
import './StackingMe.css';
import service from '../../../../../service/Service';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter, dateToString } from '../../../../common/Common';

const StackingMe = () => {

    const [stackingMe, setStackingMe] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const tableRef = useRef(null);

    useEffect(() => {
        getStackingPerPage()
    }, [currentPage])

    const getStackingPerPage = async () => {
        try {
            let res = await service.getStackingMe(limit, currentPage, 'pending')
            setStackingMe(res.data.data)
            const totalUsers = res.data.pagination.total
            setTotal(totalUsers);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleChangePage = (page) => {
        setCurrentPage(page);

        if (tableRef.current && window.innerWidth <= 600) {
            const firstRow = tableRef.current.querySelector('tbody tr');
            if (firstRow) {
                const { top } = firstRow.getBoundingClientRect();
                const scrollY = window.scrollY + top - 55; // Trừ 50px
                window.scrollTo({ top: scrollY, behavior: 'smooth' });

            }
        }
    };

    return (
        <div className="list_stacking_me">
            <div className="container-table-stacking-me">
                <table className="data-table-stacking" ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Quantity</th>
                            <th>Month saving</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stackingMe.map((row, index) => (
                            <tr key={index}>
                                <td data-label="Date">{dateToString(row.createdAt)}</td>
                                <td data-label="Name" style={{ fontWeight: '700' }}>{row.coinName}</td>
                                <td data-label="Quantity">{row.quantity}</td>
                                <td data-label="Month saving">{row.monthSaving}</td>
                                <td data-label="Status" style={{ color: row.status == 'done' ? 'green' : '' }}>{capitalizeFirstLetter(row.status)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handleChangePage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default StackingMe;
