import React, { useState, useEffect, useRef } from 'react';
import './PositionFuture.css';
import ArrowDown from '../../../../assets/images/icons/ArrowDown.png'
import Trash from '../../../../assets/images/icons/Trash.png'
import Edit from '../../../../assets/images/icons/Edit.png'
import service from '../../../../../service/Service';
import { capitalizeFirstLetter, dateToString } from '../../../../common/Common';
import swal from 'sweetalert';
import EditFuture from '../OpenFutureOrder/EditFuture';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Modal } from '@mui/material';

const buttoncss = {
  backgroundColor: '#5d6673',
  padding: '4px',
  width: '70px'
}

const PositionFuture = () => {
  const [sideFilter, setSideFilter] = useState('All');
  const [allOpenOrders, setAllOpenOrders] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState('0,0203');
  const [amount, setAmount] = useState('591');
  const [isShowTrade, setIsShowTrade] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)
  const [editPosition, setEditPosition] = useState({
    x: 0,
    y: 0
  })
  const [seletedIndex, setSelecteđIndex] = useState(0)
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([])
  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const tableRef = useRef(null);

  useEffect(() => {
    getAllOpenOrders()
  }, [])

  useEffect(() => {
    getOrderPerPage(allOpenOrders)
  }, [currentPage, sideFilter])

  const getAllOpenOrders = async () => {
    try {
      let res = await service.getFutureMe(Number.MAX_SAFE_INTEGER, 1, true)
      setAllOpenOrders(res.data.data)
      getOrderPerPage(res.data.data)
      setIsShowTrade(true)
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const getOrderPerPage = (data) => {
    let allOrders = data
      .filter(row =>
        (sideFilter === 'All' || row.type === sideFilter)
      )
    setTotal(allOrders.length)
    let result = allOrders
      .slice((currentPage - 1) * limit, currentPage * limit)
    setFilteredData(result)
  }

  const handleOpenEdit = (event, index) => {
    setSelecteđIndex(index)
    setIsOpenEdit(true)
  }
  const handleCloseEdit = () => {
    setIsOpenEdit(false)
  }

  const handleEditClick = (event, index) => {
    setIsModalOpen(true);
    const buttonPositionX = event.pageX;
    const buttonPositionY = event.pageY;
    const top = buttonPositionX - 150
    const left = buttonPositionY - 60
    setEditPosition({ x: left, y: top })
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newPrice, newAmount) => {
    setPrice(newPrice);
    setAmount(newAmount);
  };

  const handleSideSelect = (event) => {
    setSideFilter(event.target.getAttribute("data-value"));
    setCurrentPage(1)
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);

    if (tableRef.current && window.innerWidth <= 770) {
      const firstRow = tableRef.current.querySelector('tbody tr');
      if (firstRow) {
        const { top } = firstRow.getBoundingClientRect();
        const scrollY = window.scrollY + top - 55; // Trừ 50px
        window.scrollTo({ top: scrollY, behavior: 'smooth' });

      }
    }
  };

  const handleTakeProfit = async (id, coinName) => {
    try {
      await service.closeFuture(id, parseFloat(priceSymbol))
      toast.success(`Take profit ${coinName} successfully`, {
        position: 'bottom-right'
      })
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  return (
    <>
      {isShowTrade && (
        <div className="container_position_future_order">
          <div className="container-table-position-future">
            <table className="data-table-position-future" ref={tableRef}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Pair</th>
                  <th>
                    <div className="dropdown">
                      <button className="dropbtn">Type <img src={ArrowDown} width={16} height={16} /></button>
                      <div className="dropdown-content">
                        <span className={`${sideFilter === 'All' ? 'selected-dropdown' : ''}`} data-value="All" onClick={handleSideSelect}>All</span>
                        <span className={`${sideFilter === 'market' ? 'selected-dropdown' : ''}`} data-value="market" onClick={handleSideSelect}>Market</span>
                        <span className={`${sideFilter === 'limit' ? 'selected-dropdown' : ''}`} data-value="limit" onClick={handleSideSelect}>Limit</span>
                      </div>
                    </div>
                  </th>
                  <th>Entry Price</th>
                  <th>Expect Price</th>
                  <th>Leverage</th>
                  <th>Loss Stop Price</th>
                  <th>Quantity</th>
                  <th>Order Type</th>
                  <th>Edit</th>
                  <th>Take Profit</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row, index) => (
                  <tr key={index}>
                    <td data-label="Date">{dateToString(row.updatedAt)}</td>
                    <td data-label="Pair" style={{ fontWeight: '700' }}>{row.coinName}</td>
                    <td data-label="Type">{capitalizeFirstLetter(row.type)}</td>
                    <td data-label="Entry Price">{row.entryPrice}</td>
                    <td data-label="Expect Price">{row.expectPrice}</td>
                    <td data-label="Leverage">{row.leverage}x</td>
                    <td data-label="Loss Stop Price">{row.lossStopPrice}</td>
                    <td data-label="Quantity">{row.quantity}</td>
                    <td data-label="Order Type">{capitalizeFirstLetter(row.orderType)}</td>
                    <td data-label="Edit">
                      <button onClick={(event) => handleOpenEdit(event, index)}><img src={Edit} width={18} height={18} /></button>
                    </td>
                    <td data-label="Take Profit">
                      <button className='cancel_button' onClick={() => handleTakeProfit(row.id, row.coinName)} >Take Profit</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* pagination */}
          {total > 10 && (
            <div className="pagination">
              {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handleChangePage(index + 1)}
                  className={currentPage === index + 1 ? 'active' : ''}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}

          {/* edit future */}

          {(seletedIndex <= filteredData.length - 1) && (
            <Modal
              onClose={handleCloseEdit}
              open={isOpenEdit}
              className='edit-future-modal'
            >
              <div style={{ backgroundColor: '#f0f0f0' }}>
                <EditFuture
                  isOpen={isOpenEdit}
                  onClose={handleCloseEdit}
                  dataTrade={filteredData[seletedIndex]}
                />
              </div>
            </Modal>
          )}
        </div>
      )}
    </>

  );
}

export default PositionFuture;
