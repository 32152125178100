import React, { useState, useEffect } from 'react';
import './FormProfile.css';
import service from '../../../service/Service';
import { toast } from "react-toastify";
import Button from '../../components/Button/Button';
import ClipLoader from "react-spinners/ClipLoader";
import Loading from '../../components/Loading/Loading';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { Modal } from '@mui/material';
import { resizeFile } from '../../common/ResizeImage';

const FormProfile = () => {

    const [imageUrl, setImageUrl] = useState('https://datpj.s3.us-east-1.amazonaws.com/uploads/users/8145776b785400bd66878630a976016e'); // Replace with your initial image URL
    const [formData, setFormData] = useState({
        avatar: '',
        fullName: '',
        phoneNumber: '',
        birthDay: '',
        email: '',
        address: '',
        country: '',
        inviteCode: ''
    });
    const [originalFormData, setOriginalFormData] = useState({});
    const [isLoadingImage, setIsLoadingImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    useEffect(() => {
        getUserMe()
    }, []);

    const getUserMe = async () => {
        try {
            let res = await service.userMe()
            let data = res.data.data
            const profileData = data.profile || {
                avatar: 'https://datpj.s3.us-east-1.amazonaws.com/uploads/users/8145776b785400bd66878630a976016e',
                fullName: '',
                phoneNumber: '',
                birthDay: '',
                address: '',
                country: '',
                inviteCode: ''
            };
            const newFormData = {
                avatar: profileData.avatar,
                fullName: profileData.fullname,
                phoneNumber: profileData.phone,
                birthDay: formatDateToYYYYMMDD(profileData.birthDay),
                email: data.email,
                address: profileData.address,
                country: profileData.country,
                inviteCode: data.inviteCode
            };

            setImageUrl(newFormData.avatar);
            setFormData(newFormData);
            setOriginalFormData(newFormData);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const updateProfile = async () => {
        setIsLoading(true)
        try {
            let res = await service.updateProfile(formData, imageUrl)
            toast.success("Update successfully", {
                position: 'bottom-right'
            })
            setIsLoading(false)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
            setIsLoading(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // thieues avatar
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = async (event) => {
        setIsLoadingImage(true);
        const file1 = event.target.files[0];
        try {
            const file = await resizeFile(file1)
            const formData = new FormData();
            formData.append('file', file);
            let res = await service.uploadImage(formData);
            setImageUrl(res.data.data.path);
            setIsLoadingImage(false);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right',
            });
            setIsLoadingImage(false);
        }
    };

    const resetEdit = () => {
        setFormData(originalFormData);
    };

    const formatDateToYYYYMMDD = (dateString) => {
        if (!dateString) return '';
        return new Date(dateString).toISOString().split('T')[0];
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(formData.inviteCode)
            .then(() => {
                toast.success("Copied", {
                    position: 'bottom-right'
                })
            })
            .catch(err => {
            });
    };

    const onShowModal = () => {
        setShowModal(true)
    }

    const onCloseModal = () => {
        setShowModal(false)
    }
    
    const handleChangePassword = async (e) => {
        if(newPassword != confirmNewPassword) {
            toast.warning('Confirm password is incorrect!', {
                position: 'bottom-right'
            });
            setShowModal(true)
        }
        else{
            try {
                await service.changePassword(newPassword, oldPassword);
                toast.success('Your password has changed successfully!', {
                    position: 'bottom-right'
                })
                setShowModal(false)
            }
            catch(error) {
                toast.error(`${error}`, {
                    position: 'bottom-right'
                })
                setShowModal(true)
            }
        }
    }

    return (
        <>
            <Loading isLoading={isLoading} isMiddle={true} />
            <div className="profile-info">
                {/* <img src="https://via.placeholder.com/100" alt="Avatar" className="avatar" /> */}
                <div className="image-wrapper">
                    <ClipLoader
                        color="#3399fe"
                        size={35}
                        cssOverride={{
                            borderWidth: 5
                        }}
                        loading={isLoadingImage}
                        className="clip-loader"
                    />
                    <img src={imageUrl} alt="Avatar" className="avatar" />
                    <label className="overlay" htmlFor="fileInput">
                        <input
                            className="overlay"
                            id="fileInput"
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                    </label>
                    <div style={{textAlign: 'center', marginTop: '30px', fontSize: '12px'}}>
                        <a href='#' style={{color: 'white'}} onClick={onShowModal}>
                            Change password
                        </a>
                    </div>
                </div>

                <Modal onClose={onCloseModal}
                       open={showModal}
                       className='change-password-modal'
                >
                    <div>
                        <p className='change-password-modal-title'>CHANGE PASSWORD</p>
                        <form onSubmit={handleChangePassword} className='form-change-password'>
                            <label>Current password: </label>
                            <input type='password' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} required 
                                   className='change-password-button'></input>
                            <label>New password: </label>
                            <input type='password' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required
                                   className='change-password-button'></input> 
                            <label>Confirm new password: </label>  
                            <input type='password' value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} required
                                   className='change-password-button'></input>
                            <button type='submit' className='change-password-submit-button'>Update</button>
                        </form>
                    </div>
                </Modal>

                <form className="profile-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Invite code</label>
                        <div className="input-container">
                            <input
                                type="text"
                                name="inviteCode"
                                value={formData.inviteCode}
                                readOnly
                            />
                            <button onClick={handleCopy} className="copy-button">
                                <FontAwesomeIcon icon={faCopy} /> Copy code
                            </button>
                        </div>

                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label>Full name</label>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Birthday</label>
                        <input
                            type="date"
                            name="birthDay"
                            value={formData.birthDay}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Address</label>
                        <input
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Country</label>
                        <input
                            type="text"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-actions">
                        <button type="button" className="cancel-btn" onClick={() => resetEdit()}>CANCEL</button>
                        <Button
                            onClick={updateProfile}
                            size='small'
                            title='Save'
                            isGradient={true}
                            style={{ padding: '12px 25px' }}
                            isDisabled={isLoading}
                        />
                    </div>
                </form>

            </div>
        </>
    );
};
export default FormProfile;
