import React, { useEffect, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import './Loading.css';

const Loading = ({ isLoading, isMiddle = false }) => {
    let [loading, setLoading] = useState(isLoading);
    useEffect(() => {
        setLoading(isLoading)
    }, [isLoading])

    useEffect(() => {
        if (loading) {
            document.body.style.overflow = "hidden"; // Vô hiệu hóa cuộn
        } else {
            document.body.style.overflow = "auto"; // Bật lại cuộn
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [loading]);

    return (
        <div className="sweet-loading">
            {loading && (
                <div className="overlay-loading">
                    <div className="hub" style={{top: isMiddle ? '50%': '25%'}}>
                        <BounceLoader
                            color={"#3399fe"}
                            size={100} /* Điều chỉnh kích thước loader */
                            speedMultiplier={1}
                            loading={loading}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Loading;
