export const menuItems = [{
    name: "Solutions",
    path: "/",
    isSubmenu: true,
    submenuItems: [{
            name: "HyperStore",
            path: "/hyperstore",
        },
        {
            name: "HyperSDK",
            path: "/hypersdk",
        },
        {
            name: "HyperNFT",
            path: "/hypernft",
        },
    ],
},
{
    name: "Trade",
    path: "/",
    isSubmenu: true,
    submenuItems: [{
            name: "Spot",
            path: "/trade/spot",
        },
        {
            name: "Future",
            path: "/trade/future",
        },
        {
            name: "Stacking",
            path: "/trade/stacking",
        },
    ],
},
{
    name: "Community",
    path: "/",
    isSubmenu: true,
    submenuItems: [{
            name: "Telegram Chat",
            path: "https://t.me/HyperGPTai ",
        },
        {
            name: "Telegram News",
            path: "https://t.me/hypergpt_news ",
        },
        {
            name: "Discord",
            path: "https://discord.com/invite/hypergpt ",
        },
        {
            name: "Twitter",
            path: "https://twitter.com/hypergpt ",
        },

        {
            name: "LinkedIn",
            path: "https://www.linkedin.com/company/hypergpt/ ",
        },

        {
            name: "Snapshot",
            path: "https://snapshot.org/#/hypergptdao.eth ",
        },

        {
            name: "Guild",
            path: "https://guild.xyz/hypergpt ",
        },
        {
            name: "Zealy",
            path: "https://zealy.io/c/hypergpt ",
        },
    ],
},
{
    name: "Hypers",
    path: "/",
    isSubmenu: true,
    submenuItems: [{
            name: "HyperDAO ",
            path: "/dao",
        },
        {
            name: "Ambassador Program",
            path: "/ambassador-program",
        },
    ],
},
{
    name: "Learn",
    path: "/",
    isSubmenu: true,
    submenuItems: [{
            name: "Docs",
            path: "https://docs.hypergpt.ai/hypergpt/  ",
        },
        {
            name: "News",
            path: "https://medium.com/@hypergptai ",
        },
        {
            name: "Videos",
            path: "https://www.youtube.com/@HyperGPT",
        },
    ],
},

{
  name: "Contact us",
  path: "https://t.me/HyperGPTai ",
  isSubmenu: false,
},

// {
//     name: "Pricing",
//     path: "/",
//     isSubmenu: false,
// },
];