// Limit History
import React, { useState, useEffect, useRef } from 'react';
import './HistoryOrder.css';
import ArrowDown from '../../../assets/images/icons/ArrowDown.png'
import service from '../../../../service/Service';
import { capitalizeFirstLetter, dateToString } from '../../../common/Common';
import { toast } from 'react-toastify';

const HistoryOrder = () => {
    const [sideFilter, setSideFilter] = useState('All');
    const [historyAllOrders, setHistoryAllOrders] = useState([])
    const [isShowTrade, setIsShowTrade] = useState(false)
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [filteredData, setFilteredData] = useState([])
    const tableRef = useRef(null);

    useEffect(() => {
        fetchAllOrders()
    }, []);

    useEffect(() => {
        getOrderPerPage(historyAllOrders)
    }, [currentPage, sideFilter])

    const fetchAllOrders = async () => {
        try {
            const limitResponse = await service.getHistoryLimitOrders(Number.MAX_SAFE_INTEGER, 1)
            setHistoryAllOrders(limitResponse.data.data)
            getOrderPerPage(limitResponse.data.data)
            setIsShowTrade(true)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    };

    const getOrderPerPage = (data) => {
        let allOrders = data
            .filter(row =>
                (sideFilter === 'All' || row.type === sideFilter)
            )
        setTotal(allOrders.length)
        let result = allOrders
            .slice((currentPage - 1) * limit, currentPage * limit)
        setFilteredData(result)
    }

    const handleSideSelect = (event) => {
        setSideFilter(event.target.getAttribute("data-value"));
        setCurrentPage(1)
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
        if (tableRef.current && window.innerWidth <= 600) {
            const firstRow = tableRef.current.querySelector('tbody tr');
            if (firstRow) {
                const { top } = firstRow.getBoundingClientRect();
                const scrollY = window.scrollY + top - 55; // Trừ 50px
                window.scrollTo({ top: scrollY, behavior: 'smooth' });

            }
        }
    };

    return (
        <>
            {isShowTrade && (
                <div className="container_open_order">
                    <div className="container-table-spot-limit-history">
                        <table className="data-table-spot-limit-history" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Pair</th>
                                    <th>
                                        <div className="dropdown">
                                            <button className="dropbtn">Side <img src={ArrowDown} width={16} height={16} /></button>
                                            <div className="dropdown-content">
                                                <span className={`${sideFilter === 'All' ? 'selected-dropdown' : ''}`} data-value="All" onClick={handleSideSelect}>All</span>
                                                <span className={`${sideFilter === 'buy' ? 'selected-dropdown' : ''}`} data-value="buy" onClick={handleSideSelect}>Buy</span>
                                                <span className={`${sideFilter === 'sell' ? 'selected-dropdown' : ''}`} data-value="sell" onClick={handleSideSelect}>Sell</span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Expect Price</th>
                                    <th>Lost Stop Price</th>
                                    <th>Quanity</th>
                                    <th>Desc</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, index) => (
                                    <tr key={index}>
                                        <td data-label="Date">{dateToString(row.updatedAt)}</td>
                                        <td data-label="Pair" style={{ fontWeight: '700' }}>{row.coinName}</td>
                                        <td data-label="Side" style={{ color: row.type === 'sell' ? '#f2465c' : '#2c9069' }}>{capitalizeFirstLetter(row.type)}</td>
                                        <td data-label="Expect Price">{row.expectPrice}</td>
                                        <td data-label="Lost Stop Price">{row.lossStopPrice || '---'}</td>
                                        <td data-label="Quanity">{row.quantity}</td>
                                        <td data-label="Desc" style={{ color: row.desc === 'Cancel' ? 'red' : '' }}>{row.desc}</td>
                                        <td data-label="Total">{row.totalPay}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {total > 10 && (
                        <div className="pagination">
                            {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleChangePage(index + 1)}
                                    className={currentPage === index + 1 ? 'active' : ''}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>

    );
}

export default HistoryOrder;
