import React, { useEffect, useState } from "react";
import datafeed from "../datafeeds/datafeed.js";
import { widget } from "../charting_library/charting_library.esm.js";
import eventEmitter from "../../common/eventEmitter/eventEmitter.js";
import { formatNumber } from "../../common/NumberFormat.js";
import { useDispatch } from 'react-redux';
import { nameSymbol, priceSymbol } from "../../common/redux/TradeAction.js";

const TradingViewWidget = () => {
  const [price, setPrice] = useState('');
  const [symbolInfo, setSymbolInfo] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {

    window.tvWidget = new widget({
      symbol: 'Binance:BTC/USDT',           // Default symbol pair
      interval: 'D',                        // Default interval
      fullscreen: false,                      // Displays the chart in the fullscreen mode
      container: 'tv-chart-container',       // Reference to the attribute of the DOM element
      datafeed: datafeed,
      library_path: '/charting_library/',
      theme: 'Dark',
      timezone: "Asia/Ho_Chi_Minh",
      width: '100%'
    });
// Khi chart đã sẵn sàng, đăng ký listener cho sự kiện thay đổi symbol
    window.tvWidget.onChartReady(() => {
      const chart = window.tvWidget.activeChart();
      window.tvWidget.activeChart().resolution();
      chart.onSymbolChanged().subscribe(null, (newSymbol) => {  
        eventEmitter.emit('symbolInfoChanged', newSymbol.name);
        console.log("Symbol changed to:", newSymbol.name);
        // Thực hiện các hành động khác tại đây
      });
    });
    
  }, []);

  const fetchData = async (name) => {
    try {
      const response = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${name}`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const jsonData = await response.json();
      setPrice(jsonData.price);
      dispatch(priceSymbol(jsonData.price))

    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    // Đăng ký sự kiện 'symbolInfoChanged' và cập nhật state khi sự kiện này được phát
    const handleSymbolInfoChange = (newSymbolInfo) => {
      setSymbolInfo(newSymbolInfo);
      dispatch(nameSymbol(newSymbolInfo))
    };

    eventEmitter.on('symbolInfoChanged', handleSymbolInfoChange);

    // Cleanup function to remove the event listener
    return () => {
      eventEmitter.off('symbolInfoChanged', handleSymbolInfoChange);
    };
  }, []);

  useEffect(() => {
    if (symbolInfo) {
      const formattedSymbol = symbolInfo.replace(/\//g, '');
      fetchData(formattedSymbol);
      const intervalId = setInterval(() => fetchData(formattedSymbol), 2000);
      return () => clearInterval(intervalId);
    }
  }, [symbolInfo])

  return (
    <div>
      <div style={{ backgroundColor: '#0e0e0e', marginBottom: '2px', display: 'flex' }}>
        <h3 style={{ color: 'white', padding: '10px' }}>{symbolInfo}</h3>
        <h3 style={{ color: '#2dbc85', padding: '10px' }}>{formatNumber(price)}</h3>
      </div>

      <div id="tv-chart-container" />
    </div>
  );
};

export default TradingViewWidget;
