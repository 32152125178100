export default {

  REGISTER: 'v1/auth/user/register',
  LOGIN: 'v1/auth/user/login',
  VERIFYOTP: '/v1/auth/verify',
  FORGOTPASSWORD: 'v1/auth/forgot-password',
  RESETPASSWORD: 'v1/auth/reset-password',
  LOGOUT: 'v1/auth/logout',
  UPLOADIMAGE: 'v1/storage/upload/image',
  UPLOADIMAGES: 'v1/storage/upload/images',
  WALLET: 'v1/wallet/request',
  USERME: 'v1/user/me',
  UPDATEPROFILE: '/v1/user/profile',
  USER: 'v1/user',
  NOTIFICATION_ONE: 'v1/notification/send-one',
  TOKEN: 'v1/auth/token/refresh',
  TRANSFER: 'v1/wallet/request-transfer',
  NOTIFICATION: 'v1/notification',
  CHANGEPASSWORD: 'v1/auth/change-password',
 
  // limit
  LIMIT: 'v1/command/limit',
  DELETELIMITID: 'v1/command/limit/',
  LIMITME: 'v1/user/command/limit/me',
  LIMITHISTORY: '/v1/user/limit-history/me',

  // stacking
  STACKING: 'v1/stacking',
  STACKINGME: 'v1/user/stacking/me',
  STACKINGRATE: 'v1/stacking/rate',

  // market
  MARKET: 'v1/market',
  MARKETHISTORY: '/v1/user/market-history/me',

  // Future 
  FUTURE: 'v1/command/future',
  FUTUREME: 'v1/user/command/future/me',
  FUTUREHISTORY: '/v1/user/future-history/me',
  DELETEFUTUREID: '/v1/command/future',
  FUTURECOMMAND: 'v1/user/command/future/me',
  UPDATEFUTURE: 'v1/command/future/',

  // wallet
  GETWALLETME: 'v1/user/wallet/me',
  GETHISTORYME: 'v1/user/transaction-history/me',

  // verify-user
  VERIFYUSER: 'v1/verify-user' //put
};