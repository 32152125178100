import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import './HistoryTrade.css';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter, dateToString } from '../../../common/Common';
import service from '../../../../service/Service';

const HistoryTrade = () => {

    const [historyMe, setHistoryMe] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const tableRef = useRef(null);

    useEffect(() => {
        getHistoryPerPage()
    }, [currentPage])

    const getHistoryPerPage = async () => {
        try {
            let res = await service.getHistoryMe(limit, currentPage)
            setHistoryMe(res.data.data)
            const totalUsers = res.data.pagination.total
            setTotal(totalUsers);
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
        }
    }

    const handleChangePage = (page) => {
        setCurrentPage(page);

        if (tableRef.current && window.innerWidth <= 600) {
            const firstRow = tableRef.current.querySelector('tbody tr');
            if (firstRow) {
              const { top } = firstRow.getBoundingClientRect();
              const scrollY = window.scrollY + top - 55; // Trừ 50px
              window.scrollTo({ top: scrollY, behavior: 'smooth' });
      
            }
          }
    };

    return (
        <div className="container-history-trade">
            <div className="container-table-history-trade">
                <table className="data-table-history-trade" ref={tableRef}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Pair</th>
                            <th>Quantity</th>
                            <th>Type</th>
                            <th>Remain Balance</th>
                            <th>Status</th>
                            <th>Desc</th>
                        </tr>
                    </thead>
                    <tbody>
                        {historyMe.map((row, index) => (
                            <tr key={index}>
                                <td data-label="Date">{dateToString(row.createdAt)}</td>
                                <td data-label="Pair">{row.coinName.toUpperCase()}</td>
                                <td data-label="Quantity">{row.quantity}</td>
                                <td data-label="Type">{row.type}</td>
                                <td data-label="Remain Balance">{row.remainBalance}</td>
                                <td data-label="Status">{capitalizeFirstLetter(row.status)}</td>
                                <td data-label="Desc">{capitalizeFirstLetter(row.desc)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="pagination">
                {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handleChangePage(index + 1)}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default HistoryTrade;
