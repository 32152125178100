import { useEffect, useState } from 'react';
import TextFieldPrice from '../../chart/TextField/TextFieldPrice'
import SelectOptions, { ChooseTime, CustomOption } from '../../components/SelectOptions/SelectOptions';
import './BuyCrypto.scss'
import { dataBitcoin } from '../../common/dataBitcoin/dataBitcoin';
import Button from '../../components/Button/Button';
import { useParams } from 'react-router-dom';
import service from '../../../service/Service';
import { toast } from 'react-toastify';
import ClipLoader from "react-spinners/ClipLoader";
import Loading from '../../components/Loading/Loading';
import { resizeFile } from '../../common/ResizeImage';

const BuyCrypto = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOption2, setSelectedOption2] = useState(null);
    const { type } = useParams();
    const [isDisabled, setIsDisabled] = useState(true)
    const [coinName, setCoinName] = useState('')
    const [amount, setAmount] = useState(0)
    const [screenShot, setScreenShot] = useState('')
    const [screenShotUrl, setScreenShotUrl] = useState('')
    const [dataBitcoin, setDataBitcoin] = useState([])
    const [isLoadingImage, setIsLoadingImage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Hàm xử lý khi một option được chọn
    const handleSelectChange = (option) => {
        setSelectedOption(option)
        setCoinName(option.value)
    };

    const handleConfirm = async () => {
        setIsLoading(true)
        try {
            await service.requestTransfer(coinName === 'USDT' ? coinName.toLowerCase() : coinName, parseFloat(amount), type, type === 'deposit' ? screenShotUrl : " ")
            toast.success('Create transfer successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false)
        }
        catch (err) {
            toast.error(err, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getCoins()
    }, [])

    useEffect(() => {
        if (coinName === '' || amount === '' || amount == 0 || (screenShotUrl === '' && type === 'deposit') || isLoading) {
            setIsDisabled(true)
        }
        else {
            setIsDisabled(false)
        }
    }, [coinName, amount, screenShotUrl, isLoading])

    const getAmount = (amount) => {
        setAmount(amount)
    }

    const handleImageUpload = async (event) => {
        if (type === 'deposit') {
            setIsLoadingImage(true)
            setScreenShotUrl('')
            const file = event.target.files[0];
            setScreenShot(file)
            const formData = new FormData();
            let a = await resizeFile(file)
            formData.append('file', a);
            try {
                let res = await service.uploadImage(formData)
                setScreenShotUrl(res.data.data.path)
                setIsLoadingImage(false)
            } catch (error) {
                toast.error(`${error}`, {
                    position: 'bottom-right'
                })
                setIsLoadingImage(false)
            }
        }
    };

    const verifyImage = (
        <div className='coinName'>
            <label className='title'>Image of completed transaction     &nbsp;
                <ClipLoader
                    color="#3399fe"
                    size={10}
                    loading={isLoadingImage}
                />
            </label>
            <input className='input-file' type="file" accept="image/*" onChange={(e) => handleImageUpload(e)} />
        </div>
    )

    async function getCoins() {
        // https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/manifest.json
        const response = await fetch('https://api.binance.com/api/v3/exchangeInfo');
        const data = await response.json();

        const uniqueArray = data.symbols.filter((item, index, self) => {
            return self.findIndex(t => t.baseAsset === item.baseAsset) === index;
        });

        const coins = uniqueArray.map(symbolInfo => {
            const baseAsset = symbolInfo.baseAsset.toLowerCase();
            return {
                image: `https://cdn.jsdelivr.net/gh/vadimmalykhin/binance-icons/crypto/${baseAsset}.svg`,
                value: symbolInfo.baseAsset,
                label: symbolInfo.baseAsset,
            };
        });

        setDataBitcoin(coins)
    }

    return (
        <>
        <Loading isLoading={isLoading} isMiddle={true}/>
         <div className="crypto">
            <div className='crypto-box'>
                <div className='bank'>
                    Vietcombank
                </div>
                <div className='bank-account'>
                    0123456789
                </div>
                <div className='coinName'>
                    <label className='title' >Coin name</label>
                    <SelectOptions
                        value={selectedOption}
                        onSelect={handleSelectChange}
                        options={dataBitcoin}
                        view={CustomOption}

                    ></SelectOptions>
                </div>
                <div className='coinName'>
                    <label className='title'>Amount</label>
                    <TextFieldPrice title='Amount' placeholder='Enter' onChange={getAmount} unit={coinName} max={7} />
                </div>
                {type === 'deposit' ? verifyImage : ''}
                <Button
                    onClick={() => {
                        handleConfirm()
                    }}
                    title={type}
                    size="large"
                    isGradient={true}
                    key={1}
                    style={{ width: '80%' }}
                    isDisabled={isDisabled}
                />
            </div>
        </div >
        </>
    )
}

export default BuyCrypto