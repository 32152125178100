import styles from './OrderView.module.scss'
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import OpenOrder from '../OrderList/OpenOrder/OpenOrder';
import HistoryOrder from '../OrderList/HistoryOrder/HistoryOrder';
import MarketHistoryOrder from '../OrderList/HistoryOrder/MarketHistoryOrder';

const OrderView = () => {

    const [activeButton, setActiveButton] = useState(1); // 1 is buy, 2 is sell
    const [typeTrade, setTypeTrade] = React.useState('1'); // 1 is market, 2 is limit

    const handleChange = (event, newValue) => {
        setTypeTrade(newValue);
    };

    const handleClick = (buttonNumber) => {
        setActiveButton(buttonNumber);
    };

    return (
        <div className={styles.container}>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={typeTrade} >
                    <Box sx={{
                        borderBottom: 1, borderColor: 'ThreeDHighlight', position: "relative", "& .MuiTabs-indicator": {
                            position: "absolute",
                            margrinTop: '15px',
                            width: "100%",
                            backgroundColor: 'white'
                        }
                    }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            sx={{
                                "& .MuiTab-root": {
                                    minHeight: "auto", maxHeight: '100px', textTransform: "none", color: "#808080",
                                    '&.Mui-selected': {
                                        color: 'white',
                                    }
                                }
                            }}
                        >
                            <Tab
                                label="Open Orders"
                                value="1"
                                sx={{ minHeight: "auto", maxHeight: '35px', color: 'white', fontWeight: '700' }}
                            />
                            <Tab
                                label="Limit History"
                                value="2"
                                sx={{ minHeight: "auto", maxHeight: '35px', color: 'white', fontWeight: '700' }}
                            />
                            <Tab
                                label="Market History"
                                value="3"
                                sx={{ minHeight: "auto", maxHeight: '35px', color: 'white', fontWeight: '700' }}
                            />
                        </TabList>
                    </Box>
                    <TabPanel value="1" style={{ padding: 0 }}>
                        <OpenOrder />
                    </TabPanel>
                    <TabPanel value="2" style={{ padding: 0 }}>
                        <HistoryOrder />
                    </TabPanel>
                    <TabPanel value="3" style={{ padding: 0 }}>
                        <MarketHistoryOrder />
                    </TabPanel>
                </TabContext>
            </Box>

        </div>

    );
}

export default OrderView;