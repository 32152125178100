import './FutureLimitView.scss'
import { useEffect, useState } from 'react';
import DropdownInput from '../DropdownInput/DropdownInput';
import TextFieldPrice from '../TextField/TextFieldPrice';
import { useSelector } from 'react-redux';
import Button from '../../components/Button/Button';
import service from '../../../service/Service';
import { toast } from 'react-toastify';
import { formatNumber } from '../../common/NumberFormat';
import { removeAfterLastSlash, removeBeforeLastSlash, roundDown, roundUp } from '../../common/Common';
import SliderPrice from '../../components/Chart/SliderPrice';
import Loading from '../../components/Loading/Loading';

const FutureLimitView = ({ type, orderType }) => {

  const priceSymbol = useSelector((state) => state.trade.priceSymbol);
  const [price, setPrice] = useState(priceSymbol)
  const [selectedValue, setSelectedValue] = useState('1');
  const nameSymbol = useSelector((state) => state.trade.nameSymbol)
  const [amountValue, setAmountValue] = useState('')
  const [profit, setProfit] = useState('')
  const [stopLoss, setStopLoss] = useState('')
  const [percentValueMoney, setPercentValueMoney] = useState(0)
  const [percentValueLeverage, setPercentValueLeverage] = useState(0)
  const [isDisabled, setIsDisabled] = useState(true)
  const [leverage, setLeverage] = useState(1)
  const [total, setTotal] = useState('0')
  const [inputAmount, setInputAmount] = useState(0)
  const avlb = useSelector((state) => state.trade.avlbUSDT)
  let abc = formatNumber(priceSymbol)
  const [needReload, setNeedReload] = useState(true)
  const [entryPrice, setEntryPrice] = useState(abc)
  const options = [
    { value: '1', label: 'USDT' },
    { value: '2', label: removeAfterLastSlash(nameSymbol) },
  ];
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setPrice(priceSymbol)
    if (needReload) {
      setEntryPrice(formatNumber(priceSymbol))
    }

  }, [priceSymbol])

  useEffect(() => {
    setNeedReload(true)
  }, [nameSymbol])

  useEffect(() => {
    if (amountValue === '' || amountValue == 0 || profit === '' || profit == 0 || stopLoss === '' || stopLoss == 0 || isLoading) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
  }, [inputAmount, profit, stopLoss, amountValue, isLoading])

  const getPrice = (data) => {
    setPrice(data)
  }

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
    setPercentValueMoney(0)
    // setAmountValue(0)
    // setInputAmount(0)
    // input là inputamount
    if (event.target.value == '2') {
      setAmountValue(inputAmount)
      setTotal(roundUp(inputAmount * entryPrice))
    } else {
      setAmountValue(inputAmount / entryPrice)
      setTotal(inputAmount)
    }
  };

  const handleAmount = (data) => {
    setInputAmount(data)
    if (selectedValue === '2') {
      setAmountValue(data)
      setTotal(roundUp(entryPrice * data))
    } else {
      setAmountValue(data / entryPrice)
      setTotal(roundUp(data))
    }
  }

  const handleProfit = (data) => {
    setProfit(data)
  }

  const handleStopLoss = (data) => {
    setStopLoss(data)
  }

  const handleConfirm = async () => {
    if (parseFloat(roundDown(amountValue)) < 0.01) {
      toast.warning(`Quantity ${removeAfterLastSlash(nameSymbol)} of must be at least 0.01`, {
        position: 'bottom-right'
      })
    } else {
      addFuture()
    }
  }

  const addFuture = async () => {
    setIsLoading(true)
    let quantity = selectedValue === '1' ? amountValue / entryPrice : amountValue
    let entryPriceOffical = type == 'limit' ? entryPrice : formatNumber(priceSymbol)
    const value = {
      coinName: nameSymbol,
      quantity: parseFloat(roundDown(amountValue)),
      entryPrice: parseFloat(entryPriceOffical),
      expectPrice: parseFloat(profit),
      lossStopPrice: parseFloat(stopLoss),
      type: type,
      orderType: orderType,
      leverage: parseFloat(leverage)
    };

    try {
      await service.addFuture(value)
      toast.success(`${orderType === 'long' ? 'Buy' : 'Sell'} ${removeAfterLastSlash(nameSymbol)} successfully`, {
        position: 'bottom-right'
      })
      setIsLoading(false)
    } catch (error) {
      console.log('catch', error)
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
      setIsLoading(false)
    }
  }

  const handleSliderMoney = (data) => {
    setPercentValueMoney(data)
    if (selectedValue === '1') { // USDT
      setAmountValue(roundDown(data * avlb / 100))
      setInputAmount(roundDown(data * avlb / 100))
    } else {
      setAmountValue(roundDown(data * avlb / 100 / (price)))
      setInputAmount(roundDown(data * avlb / 100 / (price)))
    }
  }

  const handleSliderLeverage = (data) => {
    setPercentValueLeverage(data)
    setLeverage(data)
  }

  const handleOnChangeEntryPrice = (data) => {
    setNeedReload(false)
    setEntryPrice(data)
    if (selectedValue === '1') { 
      setAmountValue(inputAmount/data)
    } else  {
      setTotal(inputAmount * data)
    }
  }

  const priceView = () => {
    if (type == 'limit') {
      return <TextFieldPrice title='Price (USDT)' placeholder='Enter' value={entryPrice} onChange={handleOnChangeEntryPrice} type="text" />
    } else {
      return <TextFieldPrice title='Market Price' placeholder='Enter' isReadOnly={true} value={formatNumber(price)} onChange={getPrice} type="text" />
    }
  }

  return (
    <div className='abc'>
      <Loading isLoading={isLoading}/>
      {priceView()}
      <DropdownInput placeholder={selectedValue === '1' ? 'Total' : 'Amount'} defaultValue={selectedValue} options={options} onDropdownChange={handleSelectChange} onInputChange={handleAmount} value={inputAmount}></DropdownInput>
      {selectedValue === '2' && (
        <p style={{ color: 'white', textAlign: 'center', marginTop: '-20px', fontSize: '14px' }} > ≈ {total} USDT</p>
      )}

      {selectedValue === '1' && (
        <>
        <p style={{ color: 'white', textAlign: 'center', marginTop: '-20px', fontSize: '14px' }} > ≈ {roundDown(amountValue)} {removeAfterLastSlash(nameSymbol)}</p>
        <SliderPrice value={percentValueMoney} handleSliderChange={handleSliderMoney}></SliderPrice>
        </>
      )}

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <p>Avlb</p>
        <p>{avlb === null ? 0 : avlb} USDT</p>
      </div>
      {/* <br></br> */}
      <div style={{ height: '5px', width: '100%' }}></div>
      <div className='tp'>
        <input type='radio' checked></input>
        <label className='tpsl'>&nbsp;TP/SL</label>
        <label className='advanced'>Advanced</label>
      </div>
      <TextFieldPrice title='Take Profit' placeholder='Enter' value={profit} onChange={handleProfit} type="text" max={7} />
      <TextFieldPrice title='Stop Loss' placeholder='Enter' value={stopLoss} onChange={handleStopLoss} type="text" max={7} />
      {/* <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '30px'}}>
          <p>Leverage</p>
        </div> */}
      <SliderPrice value={percentValueLeverage} handleSliderChange={handleSliderLeverage} unit='x'></SliderPrice>

      <div style={{ marginTop: '30px' }}>
        <Button
          onClick={() => {
            handleConfirm()
          }}
          title={`${orderType === 'long' ? 'BUY' : 'SELL'} ${nameSymbol}`}
          size="large"
          isGradient={true}
          key={1}
          style={{ width: '100%' }}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
}

export default FutureLimitView;
