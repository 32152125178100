import { useEffect, useState } from "react";
import service from "../../../service/Service";
import { Modal } from "@mui/material";
import './Notification1.scss';

const Notification1 = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(15);
    const [total, setTotal] = useState(0);
    const [showDetail, setShowDetail] = useState(false);
    const [detailNotification, setDetailNotification] = useState([]);

    const getNotificationPerPage = async () => {
        try {
            const response = await service.getAllNotifications(limit, currentPage);
            const data = response.data.data;
            const totalNotifications = response.data.pagination.total;

            setNotifications(data);
            setTotal(totalNotifications);
        } catch (error) {
            console.error("Failed to fetch notifications for the current page:", error);
        }
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
    };

    const openDetail = async (id) => {
        const response = await service.getNotificationById(id);
        setDetailNotification(response.data.data);
        setShowDetail(true);
    };

    const closeDetail = () => {
        setShowDetail(false);
    };

    useEffect(() => {
        getNotificationPerPage();
    }, [currentPage, limit]);

    const totalPages = Math.ceil(total / limit);
    const maxPagesToShow = 10;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    return (
        <div className="dashboard">
            <table>
                <thead>
                    <tr>
                        <th>Notification</th>
                        <th className="details">Details</th>
                    </tr>
                </thead>
                <tbody>
                    {notifications.map((value, index) => (
                        <tr key={index}>
                            <td>{value.metaData.body}</td>
                            <td>
                                <button onClick={() => openDetail(value.id)}>Detail</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="pagination">
                <button
                    onClick={() => handleChangePage(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{ width: '60px' }}
                >
                    Previous
                </button>

                {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
                    const page = startPage + index;
                    return (
                        <button
                            key={page}
                            onClick={() => handleChangePage(page)}
                            className={currentPage === page ? 'active' : ''}
                        >
                            {page}
                        </button>
                    );
                })}

                <button
                    onClick={() => handleChangePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={{ width: '60px' }}
                >
                    Next
                </button>
            </div>

            <Modal onClose={closeDetail} open={showDetail} className="notification-detail">
                <div>
                    <span className="notification-detail-title">Notification detail</span>
                    <span className="notification" style={{ marginTop: '20px' }}>
                        <b>Notification content: </b>
                        <i>{detailNotification?.metaData?.body || ""}</i>
                    </span>
                    <span className="notification">
                        <b>Created date: </b>
                        <i>{(new Date(detailNotification?.createdAt)).toLocaleString('en-US') || ""}</i>
                    </span>
                    {
                        (detailNotification?.metaData?.amount || detailNotification?.metaData?.quantity) && (
                            <span className="notification">
                                <b>Coin amount: </b>
                                <i>{detailNotification?.metaData?.amount || detailNotification?.metaData?.quantity}</i>
                            </span>
                        )
                    }

                    {
                        detailNotification?.metaData?.coinName && (
                            <span className="notification">
                                <b>Coin name: </b>
                                <i>{detailNotification?.metaData?.coinName.toUpperCase() || ""}</i>
                            </span>
                        )
                    }

                    {
                        (detailNotification?.metaData?.remainBalance || detailNotification?.metaData?.type) && (
                            <span className="notification">
                                <b>
                                    {detailNotification?.metaData?.action === 'increase' || detailNotification?.metaData?.action === 'decrease' ? 'Remaining balance: ' : 'Type: '}
                                </b>
                                <i>{detailNotification?.metaData?.remainBalance || detailNotification?.metaData?.type}</i>
                            </span>
                        )
                    }

                </div>
            </Modal>
        </div>
    );
};

export default Notification1;
