
import { useState, useEffect, useRef } from "react"
import styles from '../../../js/components/Register/Register.module.scss'
import { TextField } from "../TextField/TextField"
import { Formik, Form, ErrorMessage } from "formik"
import * as Yup from "yup";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "./../../../firebase/firebase";
import { setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import service from "../../../service/Service";
import OtpInput from "../OtpInput/OtpInput";
import Loading from "../Loading/Loading";

const Register = ({ children, dismissRegister, goToLogin }) => {

    const [showOtpView, setShowOtpView] = useState(false)
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('')
    const [timer, setTimer] = useState(600);
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [inviteCode, setInviteCode] = useState('')

    const handleRegister = async (values) => {
        setIsLoading(true)
        try {
            await service.registerAuth(values.email, values.password, values.inviteCode)
            setEmail(values.email)
            setPassword(values.password)
            setInviteCode(values.inviteCode)
            toast.success("Register successfully", {
                position: 'bottom-right'
            })
            setShowOtpView(true)
            setIsLoading(false)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
            setIsLoading(false)
        }
    };

    const resendCode = async () => {
        setIsLoading(true)
        try {
            await service.registerAuth(email, password, inviteCode)
            toast.success("Resend code successfully", {
                position: 'bottom-right'
            })
            setTimer(600)
            setIsLoading(false)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
            setIsLoading(false)
        }
    }

    const validate = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string()
            .required('Required')
            .min(6, 'Must be at least 6 characters')
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/, 'Must contain at least one lowercase, uppercase, number and special character'),
        inviteCode: Yup.string(),
        isChecked: Yup.bool()
            .oneOf([true], 'You need to accept our terms and privacy policy to create an account')
    });

    const RegisterView = () => {
        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    inviteCode: "",
                    isChecked: true
                }}
                validationSchema={validate}
                onSubmit={(values) => {
                    handleRegister(values)
                }}
            >
                {(formik) => (
                    <Form className={styles.form_register}>
                        <TextField
                            label="Email"
                            name="email"
                            type="text"
                            placeholder="Email address"
                        />
                        <TextField
                            label="Password"
                            name="password"
                            type="password"
                            placeholder="Password"
                        />

                        <TextField
                            label="Invite code"
                            name="inviteCode"
                            type="text"
                            placeholder="Invite code"
                        />

                        <div className={styles.checkbox_term_policy} >
                            <TextField
                                showError={false}
                                type="checkbox"
                                name="isChecked"
                            />
                            <p>By creating an account, I agree to Bithaven's <a href="/term-service" target="_blank">Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.</p>
                        </div>
                        <ErrorMessage component="div" name="isChecked" className="error" style={{ color: 'red', fontSize: '12px' }} />

                        <button type="submit" className={styles.btn_grad} disabled={isLoading}>
                            Sign up
                        </button>
                    </Form>

                )}
            </Formik>
        )
    }

    const onChangeOTP = (value) => setOtp(value);

    const OTPView = () => {
        return (
            <div className={styles.otp_container}>
                <p >An OTP has been sent to your email {email} </p>
                <div className={styles.otp_input}>
                    <OtpInput value={otp} valueLength={6} onChange={onChangeOTP} />
                </div>
                <div className={styles.remainResend}>
                    <div className={styles.remain}>
                        <p> Time remaining&nbsp;</p>
                        <p style={{ color: 'red' }}>{formatTime(timer)}</p>
                    </div>

                    <p className={styles.resend} onClick={resendCode}> Resend code</p>
                </div>
                <button className={styles.btn_grad} onClick={verifyOtp} disabled={isLoading}> Verify </button>
            </div>

        );
    }

    useEffect(() => {
        if (showOtpView) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer <= 1) {
                        clearInterval(countdown);
                        // You can add a callback or handle expiration here
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [showOtpView]);

    const formatTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };
    
    const verifyOtp = async () => {
        let otpInt = parseInt(otp, 10)
        if (otpInt === 0 || isNaN(otpInt) || otpInt < 100000) {
            toast.warning(`OTP is invalid.`, {
                position: 'bottom-right'
            })
        }
        else {
            setIsLoading(true)
            try {
                await service.verifyOtp(email, otpInt)
                toast.success("Verify successfully", {
                    position: 'bottom-right'
                })
                dismissRegister()
                setIsLoading(false)
            } catch (error) {
                toast.error(`${error}`, {
                    position: 'bottom-right'
                })
                setIsLoading(false)
            }
        } 
    };

    return (
        <div className={styles.container_register}>
            <Loading isLoading={isLoading} isMiddle={true}/>
            {showOtpView ? (<> {OTPView()}</>) : (<RegisterView />)}
            <div className={styles.sub_register}>
                <p onClick={goToLogin} >Already have an account?</p>
            </div>
        </div>

    )
}

export default Register