import styles from './ContainerChartView.module.scss'
import OrderView from './Order/OrderView';
import SwitchTab from './Tab/SwitchTab';
import { useState } from 'react';
import OrderListFuture from './Future/OrderList/OrderListFuture';
import TradingViewWidget from './TradingViewWidget/TradingViewWidget';
import { Provider } from 'react-redux';
import { store } from './../../js/common/redux/store';
import StackingList from './Stacking/StackingList/StackingList';
import { useParams } from 'react-router-dom';

const ContainerChartView = () => {
    const [activeTab, setActiveTab] = useState('spot');
    const { option } = useParams() 

    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };

    const orderList = () => {
        // switch (activeTab) {
        //     case 'spot':
        //         return (<OrderView />)
        //     case 'future':
        //         return (
        //             <OrderListFuture />
        //         )
        //     case 'stacking':
        //         return (
        //             <StackingMe />
        //         )
        // }

        switch(option) {
            case 'spot':
                return (<OrderView />)
            case 'future':
                return (<OrderListFuture />)
            case 'stacking':
                return (<StackingList />)
        }
    }
    return (
        <Provider store={store}>
            <div className={styles.container}>
                <div className={styles.horizontal}>
                    <div className={styles.chartView}>
                        <TradingViewWidget />
                    </div>
                    <div className={styles.tradeView}>
                        <SwitchTab onTabChange={() => handleTabChange(option)} option={option} />
                    </div>
                </div>
                <div>
                    {orderList()}
                </div>
            </div>
        </Provider>
    );
}

export default ContainerChartView;