import React, { useState, useEffect, useCallback, useRef, useLayoutEffect } from 'react';
import './HistoryFuture.css';
import ArrowDown from '../../../../assets/images/icons/ArrowDown.png'
import Trash from '../../../../assets/images/icons/Trash.png'
import Edit from '../../../../assets/images/icons/Edit.png'
import EditPriceView from '../../../OrderList/OpenOrder/EditPriceView';
import service from '../../../../../service/Service';
import { capitalizeFirstLetter, dateToString } from '../../../../common/Common';
import swal from 'sweetalert';

const HistoryFuture = () => {
  const [sideFilter, setSideFilter] = useState('All');
  const [openOrders, setOpenOrders] = useState([])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [price, setPrice] = useState('0,0203');
  const [amount, setAmount] = useState('591');
  const [isShowTrade, setIsShowTrade] = useState(false)

  const [historyOrders, setHistoryOrders] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const tableRef = useRef(null);

  const [editPosition, setEditPosition] = useState({
    x: 0,
    y: 0
  })

  useEffect(() => {
    getOpenOrders()
  }, [])

  const getOpenOrders = async () => {
    try {
      let res = await service.getHistoryFutureOrders()
      console.log('ressss', res)
      setOpenOrders(res.data.data)
      setIsShowTrade(true)
    } catch (error) {

    }
  }

  useEffect(() => {
    getHistoryPerPage()
  }, [currentPage])

  const getHistoryPerPage = async () => {
    try {
      let res = await service.getHistoryFutureOrders(limit, currentPage)
      setHistoryOrders(res.data.data)
      const totalOrders = res.data.pagination.total
      setTotal(totalOrders);
    } catch (error) {
      toast.error(`${error}`, {
        position: 'bottom-right'
      })
    }
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);

    if (tableRef.current && window.innerWidth <= 920) {
      const firstRow = tableRef.current.querySelector('tbody tr');
      if (firstRow) {
        const { top } = firstRow.getBoundingClientRect();
        const scrollY = window.scrollY + top - 55; // Trừ 50px
        window.scrollTo({ top: scrollY, behavior: 'smooth' });

      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = (newPrice, newAmount) => {
    setPrice(newPrice);
    setAmount(newAmount);
  };

  const handleSideSelect = (event) => {
    setSideFilter(event.target.getAttribute("data-value"));
  };

  const filteredData = openOrders.filter(row =>
    (sideFilter === 'All' || row.type === sideFilter)
  );

  const deleteOrder = async (id) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this imaginary file!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          try {
            await service.deleteFutureWithId(id)
            swal("Poof! Your order has been deleted!", {
              icon: "success",
            });
          } catch (error) {
            swal(`${error}`, {
              icon: "error",
            });
          }
        } else { }
      });
  }

  return (
    <>
      {isShowTrade && (
        <>
          <div className="container_open_future_order">
            <div className="container-table-history-future">
              <table className="data-table-history-future" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Pair</th>
                    <th>Quanity</th>
                    <th>Expect Price</th>
                    <th>Lost Stop Price</th>
                    <th>Type</th>
                    <th>Order type</th>
                    <th>Leverage</th>
                    <th>PNL</th>
                    <th>Closing Price</th>
                    <th>Desc</th>
                  </tr>
                </thead>
                <tbody>
                  {historyOrders.map((row, index) => (
                    <tr key={index}>
                      <td data-label="Date">{dateToString(row.updatedAt)}</td>
                      <td data-label="Pair" style={{ fontWeight: '700' }}>{row.coinName}</td>
                      <td data-label="Quanity">{row.quantity}</td>
                      <td data-label="Expect Price">{row.expectPrice} </td>
                      <td data-label="Lost Stop Price">{row.lossStopPrice}</td>
                      <td data-label="Type">{capitalizeFirstLetter(row.type)}</td>
                      <td data-label="Order type">{capitalizeFirstLetter(row.orderType)}</td>
                      <td data-label="Leverage">{row.leverage}x</td>
                      <td data-label="PNL" style={{ color: row.desc == 'Closed' ? row.PNLClosed > 0 ? 'green' : 'red' : '' }}>{row.PNLClosed}</td>
                      <td data-label="Closing Price">{row.closingPrice}</td>
                      <td data-label="Desc" >{capitalizeFirstLetter(row.desc)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>

          <div className="pagination">
            {Array.from({ length: Math.ceil(total / limit) }, (_, index) => (
              <button
                key={index}
                onClick={() => handleChangePage(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      )}

    </>

  );
}

export default HistoryFuture;
