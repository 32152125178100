import React, { useState, useCallback } from 'react';
import './UploadMultipleImage.css';
import { useDropzone } from 'react-dropzone';
import closeImage from '../../../assets/images/icons/wallet/close.png'
import leftArrow from '../../../assets/images/icons/LeftArrow.png'
import Button from '../../../components/Button/Button';
import service from '../../../../service/Service';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading/Loading';
import { resizeFile } from '../../../common/ResizeImage';

const UploadMultipleImage = ({ close }) => {

    const [verifyStatus, setVerifyStatus] = useState('uploadImage')
    const [frontID, setFrontID] = useState(null);
    const [backID, setBackID] = useState(null);
    const [personalPhoto, setPersonalPhoto] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState()
    const [address, setAddress] = useState('')
    const [frontIDUrl, setFrontIDUrl] = useState(null);
    const [backIDUrl, setBackIDUrl] = useState(null);
    const [personalPhotoUrl, setPersonalPhotoUrl] = useState(null);
    const [isLoading, setIsLoading] = useState(false)

    const handleImageUpload = (e, setImage) => {
        const file = e.target.files[0];
        if (file) {
            // setImage(URL.createObjectURL(file));
            setImage(file)
        }
    };

    const convertImageToFormData = async () => {
        const formData = new FormData();
        const a = await resizeFile(frontID, false)
        const b = await resizeFile(backID, false)
        const c = await resizeFile(personalPhoto, false)
        formData.append('files', a);
        formData.append('files', b);
        formData.append('files', c);
        return formData
    }

    const uploadMultipleImages = async () => {
        if (frontID === null || backID === null || personalPhoto === null) {
            toast.warning(`Please fill out all the fields`, {
                position: 'bottom-right'
            })
        } else {
            setIsLoading(true)
            try {
                let formData = await convertImageToFormData()
                let res = await service.uploadMultipleImages(formData)
                setFrontIDUrl(res.data.data[0].path)
                setBackIDUrl(res.data.data[1].path)
                setPersonalPhotoUrl(res.data.data[2].path)
                setVerifyStatus('verifyUser')
                setIsLoading(false)
            } catch (error) {
                toast.error(`${error}`, {
                    position: 'bottom-right'
                })
                setIsLoading(false)
            }
        }
    }

    const uploadImageView = () => {
        return (
            <div className='input-image'>
                <div className='form-group'>
                    <label>Front of citizen identification card</label>
                    <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, setFrontID)} />
                    {frontID && (<label style={{ color: 'white' }} >{frontID.name}</label>)}
                </div>
                <div className='form-group'>
                    <label>Back of citizen identification card</label>
                    <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, setBackID)} />
                    {backID && (<label style={{ color: 'white' }} >{backID.name}</label>)}
                </div>
                <div className='form-group'>
                    <label>Personal Photo:</label>
                    <input type="file" accept="image/*" onChange={(e) => handleImageUpload(e, setPersonalPhoto)} />
                    {personalPhoto && (<label style={{ color: 'white' }} >{personalPhoto.name}</label>)}
                </div>

                <div style={{ width: '100%' }}>
                    <Button
                        style={{ width: '100%' }}
                        title="Next"
                        size="large"
                        isGradient={true}
                        onClick={uploadMultipleImages}
                        isDisabled={isLoading}
                    />
                </div>
            </div>
        )
    }

    const handleVerifyUser = async () => {
        setIsLoading(true)
        const value = {
            dateOfBirth: dateOfBirth,
            faceID: personalPhotoUrl,
            address: address,
            frontCitizenID: frontIDUrl,
            backCitizenID: backIDUrl,
        }
        try {
            let a = await service.verifyUser(value)
            toast.success("Verify successfully, we will response soon", {
                position: 'bottom-right'
            })
            setIsLoading(false)
        } catch (error) {
            toast.error(`${error}`, {
                position: 'bottom-right'
            })
            setIsLoading(false)
        }
    }

    const verifyUserView = () => {
        return (
            <div style={{marginTop: '20px'}}>
                <div className="form-group">
                    <label>Birthday</label>
                    <input
                        type="date"
                        name="birthDay"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Address</label>
                    <input
                        type="text"
                        name="address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                </div>

                <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <img src={leftArrow} style={{ height: '30px', marginRight: '15px' }} onClick={(e) => setVerifyStatus('uploadImage')}></img>
                    <Button
                        isDisabled={address === '' || isLoading}
                        style={{ width: '100%' }}
                        title="Verify"
                        size="large"
                        isGradient={true}
                        onClick={handleVerifyUser}
                    />
                </div>
            </div>
        )
    }


    return (
        <div className="centered-view-backdrop" >
            <Loading isLoading={isLoading} isMiddle={true}/>
            <div className='centered-view-content'>
                <div className='close-view'>
                    <img src={closeImage} onClick={close} ></img>
                </div>
                <h3>Need to verify your account, only 1 times</h3>
                <h5>Please skip if you have verified and wait for our response</h5>
                <h5>Or you can update the information if needed</h5>
                {verifyStatus === 'uploadImage' ? uploadImageView() : verifyUserView()}
            </div>
        </div>
    );
};

export default UploadMultipleImage;
